<template>
    <div class="flex flex-1 flex-col">
        <div class="card flex justify-between border-b border-solid border-gray-200 pb-4">
            <h1>{{ $t('client_details.dashboard') }}</h1>
        </div>
        <div>
            <div class="grid grid-cols-2 gap-3">
                <div class="card">
                    <ServicesTypes v-if="dashboardData.services_types_statistics" :chartData="dashboardData.services_types_statistics" />
                </div>
                <div class="card">
                    <Addresses :addressesData="dashboardData.first_address_with_total_count" />
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <div>
                    <div class="card self-start">
                        <TotalRevenue :revenueData="dashboardData.total_generated_revenue" />
                    </div>
                    <div class="card self-start">
                        <Damages :damagesData="dashboardData.total_damages_amount" />
                    </div>
                </div>
                <div class="card">
                    <ApproachingServices :servicesData="dashboardData.approaching_services" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from './dashboard.api';

export default {
    components: {
        ServicesTypes:       () => import(/* webpackChunkName: "DashboardServicesTypes" */ './DashboardServicesTypes'),
        Addresses:           () => import(/* webpackChunkName: "DashboardAddresses" */ './DashboardAddresses'),
        Damages:             () => import(/* webpackChunkName: "DashboardDashboardDamages" */ './DashboardDamages'),
        ApproachingServices: () => import(/* webpackChunkName: "DashboardApproachingServices" */ './DashboardApproachingServices'),
        TotalRevenue:        () => import(/* webpackChunkName: "DashboardTotalRevenue" */ './DashboardTotalRevenue'),
    },

    data() {
        return {
            clientUuid:    this.$route.params.uuid,
            dashboardData: {},
        };
    },

    created() {
        this.getServicesTypes();
    },

    methods: {
        async getServicesTypes() {
            this.$wait.start('loading.services_types');

            try {
                this.dashboardData = await Api.getDashboardData(this.clientUuid);
            } finally {
                this.$wait.end('loading.services_types');
            }
        },
    },
};
</script>
