export default {
    async getDetails(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: information.api.js ~ getDetails ~ error', error);
            throw error;
        }
    },

    async update(clientUuid, clientData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}`, {
                custom_id:      clientData.custom_id,
                name:           clientData.name,
                surname:        clientData.surname,
                type:           clientData.type,
                per_org_nummer: clientData.per_org_nummer,
                telephone: clientData.telephone,
                login:          clientData.login,
                manager_id:     clientData.manager_id,
                newsletter:     clientData.newsletter,
                tags:           clientData.tags,
            });
        } catch (error) {
            console.log('🚀 ~ file: information.api.js ~ update ~ error', error);
            throw error;
        }
    },

    async updatePassword(clientUuid, passwordData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/update_password`, {
                password:        passwordData.password,
                password_repeat: passwordData.password_repeat,
            });
        } catch (error) {
            console.log('🚀 ~ file: information.api.js ~ updatePassword ~ error', error);
            throw error;
        }
    },
};
