<template>
    <div class="flex flex-1 flex-col items-center">
        <ModalChangePassword
            :visible="modalChangePassword"
            @close="modalChangePassword = false"
        />

        <div class="card w-full" style="max-width: 800px">
            <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
                <h1>{{ $t('client_details.general_information') }}</h1>
            </header>

            <el-form
                ref="form"
                v-loading="$wait.is('loading')"
                :model="formData"
                :rules="rules"
                label-position="top"
                class="px-52"
                element-loading-spinner="el-custom-spinner"
            >
                <div class="border-b border-solid border-gray-200 pb-4 mb-5">
                    <el-form-item :label="$t('client_details.assign_to_manager')">
                        <el-select v-model="formData.manager_id" clearable class="w-full">
                            <el-option
                                v-for="manager in managers"
                                :key="manager.id"
                                :value="manager.id"
                                :label="manager.name + ' ' + manager.surname"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('client_details.custom_id')">
                        <el-input v-model="formData.custom_id" />
                    </el-form-item>

                    <el-form-item :label="$t('client_details.name')" prop="name">
                        <el-input v-model="formData.name" />
                    </el-form-item>

                    <el-form-item v-if="formData.type === 'private'" :label="$t('client_details.surname')">
                        <el-input v-model="formData.surname" />
                    </el-form-item>

                    <el-form-item :label="formData.type === 'company' ? 'Orgnummer' : 'Personummer'">
                        <el-input v-model="formData.per_org_nummer" />
                    </el-form-item>
                    <el-form-item label="Tel">
                        <el-input v-model="formData.telephone" />
                    </el-form-item>
                </div>

                <div class="border-b border-solid border-gray-200 pb-8 mb-5 flex flex-col ">
                    <el-form-item :label="'Email (' + $t('clients.mobile_app_login') + ')'" class="w-full">
                        <el-input v-model="formData.login" />
                    </el-form-item>
                    <el-button type="warning" @click="changePasswordModal">
                        {{ $t('clients.change_mobile_app_password') }}
                    </el-button>
                </div>

                <el-form-item :label="$t('client_details.tags')">
                    <el-select
                        v-model="formData.tags"
                        class="w-full"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                    />
                </el-form-item>
            </el-form>

            <div class="flex justify-end pt-3 mt-10 border-t border-gray-200">
                <el-button type="success" :loading="$wait.is('updating')" @click="update">
                    {{ $t('common.update') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Api from './information.api';

export default {
    components: {
        ModalChangePassword: () => import(/* webpackChunkName: "ClientInformation/ModalChangePassword" */ './InformationModalChangePassword'),
    },

    beforeRouteUpdate(to, from, next) {
        this.clientUuid = to.params.uuid;
        this.getManagers();
        this.getDetails();
        next();
    },

    data() {
        return {
            formData:                   {},
            clientUuid:                 this.$route.params.uuid,
            managers:                   [],
            modalChangePasswordVisible: false,
            modalChangePassword:        false,
            rules:                      {
                name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    created() {
        this.getManagers();
        setTimeout(() => {
            if(!this.userCan('read clients')) {
                    this.$router.push({ name: 'calendar' });
                }
            this.getDetails();
        }, 1000);
    },

    methods: {
        async getManagers() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers`);
            this.managers = data;
        },

        async getDetails() {
            this.$wait.start('loading');
            try {
                this.formData = await Api.getDetails(this.clientUuid);
            } finally {
                this.$wait.end('loading');
            }
        },

        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('updating');

            try {
                await Api.update(this.clientUuid, this.formData);
                this.$emit('clientDataChanged');
                this.$notify.success({ title: 'Success' });
            } finally {
                this.$wait.end('updating');
            }
        },

        changePasswordModal() {
            this.modalChangePassword = true;
        },
    },
};
</script>
