export default {
    async getDashboardData(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/dashboard`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: dashboard.api.js ~ getDashboardData ~ error', error);
            throw error;
        }
    },
};
